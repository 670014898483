"use client";

import { FC, useEffect, useState } from "react";
import { examplePrompts } from "@/configs/constants";
import { motion } from "framer-motion";

const RandomPrompt: FC = () => {
  const [prompt, setPrompt] = useState<string>();

  useEffect(() => {
    const getRandomPrompt = () => {
      const randomIndex = Math.floor(Math.random() * examplePrompts.length);
      if (prompt === examplePrompts[randomIndex]) {
        setPrompt(examplePrompts[randomIndex + 1]);
      } else {
        setPrompt(examplePrompts[randomIndex]);
      }
    };
    getRandomPrompt();
    // retrieve random prompt every 2 seconds
    const interval = setInterval(() => {
      getRandomPrompt();
    }, 2000);
    // cleanup
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!prompt) return null;

  return (
    <motion.div
      key={prompt}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="bg-slate-500 text-white py-3 px-2 sm:py-2 sm:px-3 rounded-md text-xs sm:text-sm"
    >
      {prompt}
    </motion.div>
  );
};

export default RandomPrompt;
