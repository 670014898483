"use client";

import { ChangeEvent, FC, FormEvent, useState } from "react";
import { smallClickAnimation } from "@/configs/framer.config";
import { ChatRequestOptions } from "ai";
import { motion } from "framer-motion";
import { ArrowUp } from "lucide-react";
import AgentButton, { Agent } from "../molecules/AgentButton";

interface Props {
  input: string;
  isLoading: boolean;
  handleSubmit: (
    e: FormEvent<HTMLFormElement>,
    chatRequestOptions?: ChatRequestOptions | undefined
  ) => void;
  handleInputChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => void;
  selectedAgent: Agent;
  setSelectedAgent: (agent: Agent) => void;
}

const ChatInput: FC<Props> = ({
  input,
  isLoading,
  handleSubmit,
  handleInputChange,
  selectedAgent,
  setSelectedAgent,
}) => {
  return (
    <div className="w-full flex flex-col gap-2 items-center">
      <form onSubmit={handleSubmit} className="relative w-full">
        <div className="flex flex-row gap-2 justify-center">
          <AgentButton
            isLoading={isLoading}
            selectedAgent={selectedAgent}
            setSelectedAgent={setSelectedAgent}
          />
          <textarea
            className="p-3 h-[51px] min-h-[51px] max-h-[250px] w-3/4 flex-grow rounded-md text-black"
            placeholder="Message SolanAI"
            value={input}
            onChange={handleInputChange}
          />
          <motion.button
            {...smallClickAnimation}
            className="group bg-solana-green text-black h-[51px] w-[55px] flex items-center justify-center
            rounded-md disabled:bg-gray-100 disabled:text-gray-500 text-xs sm:text-base"
            disabled={isLoading}
          >
            <ArrowUp
              className={`${
                !isLoading && "arrow-bounce group-hover:text-white"
              }`}
              size={24}
            />
          </motion.button>
        </div>
      </form>
      <div className="text-xs text-center">
        SolanAI can make mistakes. Always double check important information.
      </div>
    </div>
  );
};

export default ChatInput;
