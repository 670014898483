"use client";

import { FC } from "react";
import { Twitter } from "lucide-react";
import Discord from "../@icons/Discord";
import Link from "next/link";

const Socials: FC = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center gap-4 lg:gap-6">
      <div className="flex items-center gap-6">
        <a
          href="https://discord.gg/pmbc4NjctV"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Discord className="fill-white" />
        </a>
        <a
          href="https://twitter.com/trustlesseng"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter size={20} fill="white" />
        </a>
      </div>
      <div className="w-full flex items-center justify-around gap-6">
        <Link href="/legal/tos" className="text-xs lg:textbase hover:underline">
          Terms
        </Link>
        <Link href="/legal/aup" className="text-xs lg:textbase hover:underline">
          Acceptable Use
        </Link>
        <Link
          href="/legal/privacy"
          className="text-xs lg:textbase hover:underline"
        >
          Privacy
        </Link>
      </div>
    </div>
  );
};

export default Socials;
