"use client";

import { ChatInput, Message } from "@/components";
import {
  addMessages,
  createConversation,
  getFeedback,
} from "@/lib/conversations";
import { getLogger } from "@/lib/logger";
import { useAuth, useUser } from "@clerk/nextjs";
import {
  Conversation,
  Message as MessageType,
  ResponseFeedback,
} from "@prisma/client";
import { Message as AIMessageType, useChat } from "ai/react";
import { useRouter } from "next/navigation";
import { FC, useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { Agent } from "../molecules/AgentButton";

const logger = getLogger();
export const revalidate = 0;

type ConvoWithMessages = Conversation & { messages: MessageType[] };

type ChatProps = {
  previousConvo?: ConvoWithMessages;
};

const Chat: FC<ChatProps> = ({ previousConvo }) => {
  const { getToken } = useAuth();
  const router = useRouter();
  const { user } = useUser();

  const messageContainerRef = useRef<HTMLDivElement>(null);

  const [authToken, setAuthToken] = useState<string | null>();
  const [conversation, setConversation] = useState<
    ConvoWithMessages | undefined
  >(previousConvo);
  const [currentFeedback, setCurrentFeedback] = useState<ResponseFeedback[]>(
    []
  );
  const [selectedAgent, setSelectedAgent] = useState<Agent>(Agent.AUTO);

  const previousMessages = previousConvo?.messages || [];

  const { isLoading, messages, input, handleInputChange, handleSubmit } =
    useChat({
      initialMessages: previousMessages as AIMessageType[],
      body: {
        conversationId: conversation?.id,
      },
      onFinish: (message: AIMessageType) => {
        if (!user) return;
        logger.info("AI Response: ", message);
      },
      onError: (err: Error) => {
        if (err.message.includes("not enough credits")) {
          toast.error("You don't have enough credits to send this message.");
        }
        toast.error("We crossed some wires and the AI couldn't respond.");
        console.error("AI Error: ", err);
      },
      onResponse: (response: Response) => {
        logger.info(response, "ai response");
      },
      headers: {
        "X-Agent-Type": selectedAgent,
      },
    });

  // fetch all feedback for this conversation
  const fetchFeedback = async () => {
    if (!conversation) return;
    const response = await getFeedback(conversation.id);
    // logger.info("feedback response: ", response);
    setCurrentFeedback(response);
  };

  useEffect(() => {
    if (!conversation) return;
    fetchFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }
    };

    // call the scrollToBottom function when messages update
    if (messages && messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (!user) return;

    const updateConvo = async () => {
      if (!conversation) {
        const convo = await createConversation(user.id, messages);
        router.push(`/conversation/${convo.id}`);
      } else {
        const newMessages = messages.filter(
          (msg) =>
            !conversation.messages.some(
              (existingMsg) => existingMsg.id === msg.id
            )
        );

        if (newMessages && newMessages.length > 0) {
          const convo = await addMessages(conversation.id, newMessages);
          setConversation(convo as ConvoWithMessages);
        }
      }
    };

    if (messages && messages.length > 0 && !isLoading) {
      updateConvo();
    }
  }, [user, conversation, setConversation, messages, router, isLoading]);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getToken();
      setAuthToken(token);
    };

    if (!user) return;

    if (!authToken) {
      fetchToken();
    }
  }, [authToken, conversation, getToken, messages, setConversation, user]);

  return (
    <div className="flex flex-col flex-grow h-full justify-between gap-4">
      <div
        ref={messageContainerRef}
        className="w-[102%] messageContainer self-start overflow-y-scroll flex flex-col gap-4 py-2"
      >
        {/* Messages will scroll in this container */}
        {messages &&
          messages.map((m) => (
            <Message
              key={m.id}
              message={m}
              currentFeedback={currentFeedback.find(
                (f) => f.messageId === m.id
              )}
              fetchFeedback={fetchFeedback}
            />
          ))}
        {isLoading && (
          <Message
            message={{
              id: "loading",
              role: "assistant",
              content: "Thinking...",
            }}
            currentFeedback={undefined}
            fetchFeedback={fetchFeedback}
          />
        )}
      </div>
      <ChatInput
        input={input}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        handleInputChange={handleInputChange}
        selectedAgent={selectedAgent}
        setSelectedAgent={setSelectedAgent}
      />
    </div>
  );
};

export default Chat;
