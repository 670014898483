"use client";

import { FC } from "react";
import { Message as AIMessageType } from "ai/react";
import { ResponseFeedback } from "@prisma/client";
import { AIMessageBox, UserMessageBox } from "@/components";

interface Props {
  message: AIMessageType;
  currentFeedback: ResponseFeedback | undefined;
  fetchFeedback: () => Promise<void>;
}

const Message: FC<Props> = ({ message, currentFeedback, fetchFeedback }) => {
  return (
    <div className="h-full">
      {message.role === "user" && <UserMessageBox message={message} />}
      {message.role === "assistant" && (
        <AIMessageBox
          message={message}
          feedback={currentFeedback}
          refetchFeedback={fetchFeedback}
        />
      )}
    </div>
  );
};

export default Message;
