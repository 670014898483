import dynamic from "next/dynamic";

// atoms
const RandomPrompt = dynamic(() => import("./atoms/RandomPrompt"));
const Socials = dynamic(() => import("./atoms/Socials"));

// molecules
const AIMessageBox = dynamic(() => import("./molecules/AIMessageBox"));
const BGEffect = dynamic(() => import("./molecules/BGEffect"));
const Footer = dynamic(() => import("./molecules/Footer"));
const Modal = dynamic(() => import("./molecules/Modal"));
const Nav = dynamic(() => import("./molecules/Nav"));
const UserMessageBox = dynamic(() => import("./molecules/UserMessageBox"));

// organisms
const ChatInput = dynamic(() => import("./organisms/ChatInput"));
const FeedbackModal = dynamic(() => import("./organisms/FeedbackModal"));
const Message = dynamic(() => import("./organisms/Message"));

// templates
const Chat = dynamic(() => import("./templates/Chat"));
const ClientWrapper = dynamic(() => import("./templates/ClientWrapper"));

export {
  // molecules
  AIMessageBox,
  // atoms
  BGEffect,
  Chat,
  // organisms
  ChatInput,
  // templates
  ClientWrapper,
  FeedbackModal,
  Footer,
  Message,
  Modal,
  Nav,
  RandomPrompt,
  Socials,
  UserMessageBox,
};
