"use client";

import { FC, useRef, useState } from "react";
import { slideUp, smallClickAnimation } from "@/configs/framer.config";
import { AnimatePresence, motion } from "framer-motion";
import {
  ChevronDown,
  ChevronUp,
  Eye,
  PiggyBank,
  ScrollText,
  Sparkles,
} from "lucide-react";
import Engineer from "../@icons/Engineer";
import { useOutsideAlerter } from "@/hooks";

export enum Agent {
  AUTO = "auto",
  HISTORIAN = "historian",
  ENGINEER = "engineer",
  OBSERVER = "observer",
  INVESTOR = "investor",
}

const agentData = {
  [Agent.AUTO]: {
    title: "Auto (Default)",
    icon: <Sparkles size={24} color="white" />,
    description:
      "Using a socratic style you will receive the best response according to all agents.",
  },
  [Agent.HISTORIAN]: {
    title: "Historian",
    icon: <ScrollText size={24} color="white" />,
    description:
      "Largest vector store of blogs, articles, and sentiment contexts aggregated from the others.",
  },
  [Agent.ENGINEER]: {
    title: "Engineer",
    icon: <Engineer width={24} height={24} fill="white" />,
    description: "Ideal for programming discussions and API calls",
  },
  [Agent.OBSERVER]: {
    title: "Observer",
    icon: <Eye size={24} color="white" />,
    description:
      "Watches on-chain actions and social media and updates other agents.",
  },
  [Agent.INVESTOR]: {
    title: "Investor",
    icon: <PiggyBank size={24} color="white" />,
    description:
      "Vector store built on Investopedia and then hypertuned to solana topics.",
  },
};

interface Props {
  isLoading: boolean;
  selectedAgent: Agent;
  setSelectedAgent: (agent: Agent) => void;
}

const AgentButton: FC<Props> = ({
  isLoading,
  selectedAgent,
  setSelectedAgent,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const selectorRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(selectorRef, (event: MouseEvent) => {
    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      return;
    }
    setShowOptions(false);
  });

  const slideUpAnimation = slideUp(true, 20, 0.3, 0, 0);

  return (
    <>
      <motion.button
        className="bg-btn-grey-base hover:bg-btn-grey-hover text-white h-[51px] flex
        items-center justify-center gap-2 px-4 rounded-md text-xs sm:text-base"
        ref={buttonRef}
        type="button"
        {...smallClickAnimation}
        disabled={isLoading}
        onClick={() => {
          setShowOptions(!showOptions);
        }}
      >
        {agentData[selectedAgent]["icon"]}
        {showOptions ? (
          <ChevronUp size={12} color="white" />
        ) : (
          <ChevronDown size={12} color="white" />
        )}
      </motion.button>
      <AnimatePresence mode="wait">
        {showOptions && (
          <motion.div
            ref={selectorRef}
            key="selector"
            {...slideUpAnimation}
            className="absolute bottom-[130%] right-0 w-full bg-btn-grey-base
            rounded-md shadow-xl border border-btn-grey-hover"
          >
            <div className="flex flex-col gap-4 p-4">
              {Object.values(Agent).map((agent) => (
                <motion.button
                  key={agent}
                  className={`w-full flex flex-row gap-4 items-center
                  rounded-md disabled:bg-gray-100 text-xs sm:text-base
                  hover:bg-btn-grey-hover p-2 ${
                    selectedAgent === agent ? "bg-btn-grey-hover" : ""
                  }`}
                  disabled={isLoading}
                  onClick={() => {
                    setSelectedAgent(agent);
                    setShowOptions(false);
                  }}
                >
                  {agentData[agent]["icon"]}
                  <div className="flex flex-col items-start gap-1">
                    <p className="white text-sm">{agentData[agent]["title"]}</p>
                    <p className="text-xs text-left text-description-grey">
                      {agentData[agent]["description"]}
                    </p>
                  </div>
                </motion.button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AgentButton;
