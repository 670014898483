"use client";

import { FC, useState } from "react";
import { FeedbackModal } from "@/components";
import { submitFeedback } from "@/lib/conversations";
import { useUser } from "@clerk/nextjs";
import { ResponseFeedback } from "@prisma/client";
import { Message as AIMessageType } from "ai";
import { motion } from "framer-motion";
import { MessageCircle, ThumbsDown, ThumbsUp } from "lucide-react";
import { toast } from "sonner";

import { getLogger } from "@/lib/logger";
const logger = getLogger();

interface Props {
  message: AIMessageType;
  feedback: ResponseFeedback | undefined;
  refetchFeedback: () => Promise<void>;
}

const AIMessageBox: FC<Props> = ({ message, feedback, refetchFeedback }) => {
  const { user } = useUser();

  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [newFeedback, setNewFeedback] = useState<ResponseFeedback>({
    messageId: message.id,
    sentiment: null,
    details: null,
  });

  const handleFeedback = async (newFeedback: ResponseFeedback) => {
    if (feedback?.details === newFeedback.details) {
      toast.error("You have already submitted this feedback!");
      return;
    }
    const toastId = feedback?.details
      ? toast.loading("Updating feedback...")
      : toast.loading("Submitting feedback...");
    try {
      await submitFeedback(newFeedback);
      await refetchFeedback();
      toast.success("Feedback submitted!", { id: toastId });
      setFeedbackOpen(false);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!", { id: toastId });
    }
  };

  // useEffect(() => {
  //   logger.info("current feedback for this message: ", feedback);
  // }, [feedback]);

  return (
    <>
      <div
        key={message.id}
        className={`relative h-full w-full p-4 rounded-md flex flex-col gap-2 overflow-x-auto
            ${
              message.role === "user"
                ? "bg-white text-slate-700"
                : "bg-slate-700 text-white pb-10"
            }`}
      >
        <p>
          {message.role === "user"
            ? `${user?.username || "user"}:`
            : "Solan.ai: "}
        </p>
        <p className="h-full pb-4">{message.content}</p>
        {/* && !!previousConvo */}
        {message.role !== "user" && message.id !== "loading" && (
          <div className="absolute bottom-3 right-3 flex items-center gap-2">
            {!!feedback && feedback.sentiment === "good" ? (
              <motion.button
                className="border border-solana-green rounded-full p-2 group"
                onClick={() => {
                  if (feedback.details !== null) {
                    setNewFeedback({
                      details: feedback.details,
                      messageId: message.id,
                      sentiment: "good",
                    });
                  } else {
                    setNewFeedback((prev) => ({
                      ...prev,
                      messageId: message.id,
                      sentiment: "good",
                    }));
                  }
                  setFeedbackOpen(true);
                }}
              >
                <MessageCircle
                  fill="#14F195"
                  color="#14F195"
                  size={20}
                  className="group-hover:scale-105 transition-all duration-300"
                />
              </motion.button>
            ) : (
              <motion.button
                className="p-2 group"
                onClick={() => {
                  handleFeedback({
                    messageId: message.id,
                    sentiment: "good",
                    details: null,
                  });
                }}
              >
                <ThumbsUp
                  size={20}
                  className="group-hover:text-solana-green group-hover:scale-105 transition-all duration-300"
                />
              </motion.button>
            )}

            {!!feedback && feedback.sentiment === "bad" ? (
              <motion.button
                className="border border-red-300 rounded-full p-2 group"
                onClick={() => {
                  if (feedback.details !== null) {
                    setNewFeedback({
                      details: feedback.details,
                      messageId: message.id,
                      sentiment: "bad",
                    });
                  } else {
                    setNewFeedback((prev) => ({
                      ...prev,
                      messageId: message.id,
                      sentiment: "bad",
                    }));
                  }
                  setFeedbackOpen(true);
                }}
              >
                <MessageCircle
                  fill="rgb(252 165 165)"
                  color="rgb(252 165 165)"
                  size={20}
                  className="group-hover:scale-105 transition-all duration-300"
                />
              </motion.button>
            ) : (
              <motion.button
                className="p-2 group"
                onClick={() => {
                  handleFeedback({
                    messageId: message.id,
                    sentiment: "bad",
                    details: null,
                  });
                }}
              >
                <ThumbsDown
                  size={20}
                  className="group-hover:text-red-300 group-hover:scale-105 transition-all duration-300"
                />
              </motion.button>
            )}
          </div>
        )}
      </div>
      {!!newFeedback && (
        <FeedbackModal
          feedbackOpen={feedbackOpen}
          setFeedbackOpen={setFeedbackOpen}
          newFeedback={newFeedback}
          setNewFeedback={setNewFeedback}
          handleFeedback={handleFeedback}
        />
      )}
    </>
  );
};

export default AIMessageBox;
