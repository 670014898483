"use client";

import { Dispatch, FC, SetStateAction, useRef } from "react";
import { useOutsideAlerter } from "@/hooks/useOutsideAlerter";
import { X } from "lucide-react";

interface ModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  disableOutsideClick?: boolean;
  children: React.ReactNode;
}

const Modal: FC<ModalProps> = ({
  showModal,
  setShowModal,
  disableOutsideClick = false,
  children,
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    if (disableOutsideClick) return;
    setShowModal(false);
  });

  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden 
            fixed inset-0 top-4 bottom-4 sm:top-0 sm:bottom-0 z-50 outline-none 
            focus:outline-none mx-4 backdrop-blur-sm"
          >
            <div
              className="relative w-full sm:w-[90%] lg:w-2/3 xl:w-1/2 my-6 mx-auto max-w-lg h-fit"
              ref={wrapperRef}
            >
              <div className="relative p-5 shadow-lg flex flex-col w-full bg-btn-grey-base rounded-lg">
                {/* header */}
                <div className="w-full">
                  <button
                    className="absolute right-5 top-5"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    <X size={24} color="white" />
                  </button>
                </div>
                {/* content body */}
                {children}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
    </>
  );
};

export default Modal;
