"use client";

import { useUser } from "@clerk/nextjs";
import { Message as AIMessageType } from "ai/react";
import { FC } from "react";

interface Props {
  message: AIMessageType;
}

const UserMessageBox: FC<Props> = ({ message }) => {
  const { user } = useUser();

  return (
    <div
      key={message.id}
      className={`relative w-full h-full p-4 rounded-md flex flex-col gap-2 bg-white text-slate-700 overflow-x-auto`}
    >
      <p>{user?.username || "User"}</p>
      <p className="h-full">{message.content}</p>
    </div>
  );
};

export default UserMessageBox;
