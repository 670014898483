export const examplePrompts = [
  "What is proof of history?",
  "What is the current TPS?",
  "What is the current price of SOL?",
  "How was Solana created?",
  "What makes Solana different from other blockchains?",
  "How quick is a Solana transaction on average?",
  "What is an ATA?",
  "Who created Solana? How is it unique?",
];

export const lookingGlassUrl = process.env.LOOKING_GLASS_URL!;
// TEC Squads v3 multi-sig
export const treasuryAddress = "23Q5e33JnmKWACqmmYW1owRwfs7ToD4SuCTzfDGxMcfA";
export const creditsPriceInSol = 0.01;

// discord
export const TEC_GUILD = "1181661836026839060";
export const PAL_ROLE = "1182308570700709928";
export const ALPHA_ROLE = "1182047022358994955";
