"use client";
import { SignInButton, UserButton, useUser } from "@clerk/nextjs";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

const Nav: FC = () => {
  const { user } = useUser();

  return (
    <>
      <nav className="w-full sticky top-0 backdrop-blur-sm flex items-start md:items-center justify-between px-5 sm:px-10 py-8">
        <div className="flex flex-col sm:flex-row items-center gap-3">
          <Link href="/">
            <Image
              src="/images/logo_new.svg"
              width={150}
              height={24}
              alt="logo"
            />
          </Link>
          <div className="w-full sm:w-auto text-center text-xs sm:text-sm px-2 py-1 bg-solana-green text-black rounded-md">
            Closed Alpha
          </div>
        </div>
        <>
          {user ? (
            <div className="flex items-center gap-3 md:gap-5">
              <div className="flex items-center gap-2">
                <UserButton afterSignOutUrl="/" />
                <p className="hidden md:block">
                  {user.username ?? user.fullName}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-3 md:gap-6">
              <SignInButton />
            </div>
          )}
        </>
      </nav>
    </>
  );
};

export default Nav;
