"use client";

import { FC } from "react";
import { Socials } from "@/components";

const Footer: FC = () => {
  return (
    <div className="select-none w-full flex flex-col lg:flex-row items-center gap-3 lg:gap-0 lg:justify-between px-10 py-5 z-10">
      <Socials />
      <p className="text-xs">&copy; 2023 Trustless Engineering Corp.</p>
    </div>
  );
};

export default Footer;
